import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { NO_CACHE_HEADER } from '@psu/utils/browser';
import { REQUIRE_AUTH_HEADER } from '@psu/utils/security';
import {
  CourseConfig,
  Delegation,
  OrgModel,
  RoleConfig,
  SelectionConfig,
  ServerCourseConfiguration,
} from '@starfish-access/models';
import { OrgSelectItem } from '@starfish-access/shared';
import { filter } from 'ramda';
import { concat, forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, map, mergeMap, toArray } from 'rxjs/operators';
import { STARFISH_CONFIG, StarfishConfig } from '../../starfish.config';
import {
  BulkSearchEndpointType,
  CalendarManagementType,
  ClassConfig,
  ClientAssignmentResponse,
  ClientAssignmentTransitionStatus,
  ClientCurrentSemester,
  ClientTransitionType,
  ConfiguredAssignments,
  ConfiguredDelegation,
  ConfiguredOptions,
  DelegationMetaData,
  RoleSelectionType,
  SelectionType,
  UserInfo,
} from '../model/delegation.model';
import { FEATURES_CONFIG, FeaturesConfig } from '../model/features.model';
import { ClientCourseSelectionType } from '../model/roles.model';
import {
  ServerAssignedOrgs,
  ServerCalendarManager,
  ServerCoursesOptions,
  ServerCurrentSemester,
  ServerDelegateRole,
  ServerDelegationAssignment,
  ServerDelegationGroup,
  ServerMigrationStatus,
  ServerOrgConfiguration,
  ServerOrgItem,
} from '../model/server/server-delegate.model';
import { ServerKioskConfiguration, ServerServiceConfiguration } from '../model/server/server-roles.model';
import { handleErrorFromRest } from '../utils/utils.model';
import { headers } from './service.utils';

interface idCheckModel {
  psuid: string;
  userid: string;
  displayName: string;
}

const orgSelectItemToOrgModel = (input: OrgSelectItem): ServerOrgItem => ({
  code: input.id,
  name: input.orgName,
});

const orgModelToOrgSelectItem = (input: ServerOrgItem): OrgSelectItem => ({
  displayName: `${input.name} [${input.code}]`,
  id: input.code,
  orgName: input.name,
});

@Injectable()
export class DelegateService {
  constructor(
    @Inject(FEATURES_CONFIG) private featureConfig: FeaturesConfig,
    private http: HttpClient,
    @Inject(STARFISH_CONFIG) private starfishConfig: StarfishConfig
  ) {}

  userValidationSearch(idList: UserInfo[], endpoint: BulkSearchEndpointType): Observable<UserInfo[]> {
    const users: string[] = idList?.map((res) => res.id);
    const url = this.starfishConfig.searchService + '/' + endpoint;
    return this.http.post<idCheckModel[]>(url, users, { headers }).pipe(
      map((res) =>
        res.map((checkModel) => ({
          id: endpoint === '.bulkSearchPsuid' ? checkModel.psuid : checkModel.userid,
          displayName: checkModel.displayName,
        }))
      ),
      catchError((e) => {
        console.error(e);
        return of(idList);
      })
    );
  }

  // example: starfish-access/resources/delegationGroups/assignmentCsv?delegationId=48
  downloadAssignmentReport(delegationId: string): Observable<Blob | null> {
    const reportUrl =
      this.starfishConfig.starfishServices + 'delegationGroups/assignmentCsv?delegationId=' + delegationId;

    const downloadHeaders = new HttpHeaders()
      .append('Content-Type', 'application/octet-stream')
      .append('Accept', 'application/octet-stream')
      .append(NO_CACHE_HEADER, 'true')
      .append(REQUIRE_AUTH_HEADER, 'true');

    return this.http
      .get(reportUrl, {
        headers: downloadHeaders,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map((res) => res.body),
        catchError(handleErrorFromRest)
      );
  }

  removeDelegationRole(delegationId: string, roleDelegationId: string, force: boolean): Observable<number> {
    const groupsUrl =
      this.starfishConfig.starfishServices + 'delegationGroups/' + delegationId + '/delegatedRole/' + roleDelegationId;

    return this.http.delete<Response>(groupsUrl, { headers }).pipe(
      map((res) => 0),
      catchError((e) => {
        // a 409 is being used as a successful response code
        if (e.status === 409) {
          const numOfAssignments: number = e.error ? e.error : 0;
          return of(numOfAssignments);
        }
        return throwError(e);
      })
    );
  }

  deleteConfiguredAssignment(delegationId: number, assignmentId: number): Observable<Response> {
    const groupsUrl =
      this.starfishConfig.starfishServices + 'delegationGroups/' + delegationId + '/assignments/' + assignmentId;

    return this.http.delete<Response>(groupsUrl, { headers }).pipe(
      map((res) => res),
      catchError(handleErrorFromRest)
    );
  }

  getConfiguredDelegationById(id: string): Observable<ConfiguredDelegation> {
    const groupsUrl = this.starfishConfig.starfishServices + 'delegationGroups/' + id;

    return this.http.get<ServerDelegationGroup>(groupsUrl, { headers }).pipe(
      map((result) => {
        const d: Delegation = this.toClientDelegationGroup(result);
        const cd: ConfiguredDelegation = {
          assignedRoles: [],
          delegation: d,
        };
        return cd;
      }, catchError(handleErrorFromRest))
    );
  }

  getConfiguredDelegations(): Observable<ConfiguredDelegation[]> {
    const groupsUrl = this.starfishConfig.starfishServices + 'delegationGroups';
    return this.http.get<ServerDelegationGroup[]>(groupsUrl, { headers }).pipe(
      map((result) => {
        const clientList: ConfiguredDelegation[] = [];
        result.forEach((element) => {
          const d: Delegation = this.toClientDelegationGroup(element);
          const cd: ConfiguredDelegation = {
            assignedRoles: [],
            delegation: d,
          };
          clientList.push(cd);
        });
        return clientList.sort((a, b) =>
          a.delegation.name.toLowerCase().localeCompare(b.delegation.name.toLowerCase())
        );
      }),
      catchError(handleErrorFromRest)
    );
  }

  getDelegationGroupById(id: string): Observable<Delegation> {
    const groupsUrl = this.starfishConfig.starfishServices + 'delegationGroups/' + id;

    return this.http
      .get<ServerDelegationGroup>(groupsUrl, { headers })
      .pipe(map((result) => this.toClientDelegationGroup(result), catchError(handleErrorFromRest)));
  }

  getAllDelegationGroups(): Observable<Delegation[]> {
    const groupsUrl = this.starfishConfig.starfishServices + 'delegationGroups';

    return this.http.get<ServerDelegationGroup[]>(groupsUrl, { headers }).pipe(
      map((result) => {
        const clientList: Delegation[] = [];
        result.forEach((element) => {
          clientList.push(this.toClientDelegationGroup(element));
        });
        return clientList.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
      }),
      catchError(handleErrorFromRest)
    );
  }

  getAllDelegationGroupsMetaData(): Observable<DelegationMetaData[]> {
    const groupsUrl = this.starfishConfig.starfishServices + 'delegationGroups?nameIdOnly=true';

    return this.http.get<ServerDelegationGroup[]>(groupsUrl, { headers }).pipe(
      map((result) => {
        const clientList: DelegationMetaData[] = [];
        result.forEach((element) => {
          clientList.push(this.toClientDelegationGroupMetaData(element));
        });
        return clientList.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
      }),
      catchError(handleErrorFromRest)
    );
  }

  getDelegationAssignments(cd: ConfiguredDelegation): Observable<ConfiguredAssignments[]> {
    const groupsUrl = this.starfishConfig.starfishServices + 'delegationGroups/' + cd.delegation.id + '/assignments';
    const userInfoUrl = this.starfishConfig.searchService + '/.bulkSearchUserid';

    // we need to know both the transition status of specific assignments
    // and what assignments are available to this delegation, before we can
    // respond

    return forkJoin([
      this.getDelegationAssignmentsTransitionStatus(cd),
      this.http.get<ServerDelegationAssignment[]>(groupsUrl, { headers }),
    ]).pipe(
      mergeMap((res) => {
        const bulkUserIds: string[] = res[1]?.map((role) => role.userid);
        return forkJoin([of(res), this.http.post<idCheckModel[]>(userInfoUrl, bulkUserIds, { headers })]);
      }),
      map((res) => {
        const cats: ClientAssignmentTransitionStatus[] = res[0][0];
        const sda: ServerDelegationAssignment[] = res[0][1];
        const users = res[1];

        const clientList: ConfiguredAssignments[] = [];

        // Map the userId to userName ['userId' : 'username'] so we can set it when creating the client object
        const displayName = users.reduce((previousUser: Record<string, string>, currentUser) => {
          previousUser[currentUser.userid] = currentUser.displayName;
          return previousUser;
        }, {});

        sda.forEach((element) => {
          clientList.push(
            this.toClientDelegationAssignment(
              element,
              cats,
              cd.delegation.roleConfigs,
              cd.delegation.calendarManagers.calendarManagementExclusions,
              displayName[element.userid]
            )
          );
        });
        return clientList;
      }),
      catchError(handleErrorFromRest)
    );
  }

  updateExistingDelegationGroup(delegation: Delegation): Observable<Delegation> {
    const url = this.starfishConfig.starfishServices + 'delegationGroups/' + delegation.id;

    const serverDelegation: ServerDelegationGroup = this.toServerDelegationGroup(delegation);
    serverDelegation.id = delegation.id;

    return this.http
      .put<ServerDelegationGroup>(url, serverDelegation, {
        headers,
      })
      .pipe(
        map((res) => this.toClientDelegationGroup(res)),
        catchError(handleErrorFromRest)
      );
  }

  createNewDelegationGroup(delegation: Delegation): Observable<Response> {
    const serverDelegation: ServerDelegationGroup = this.toServerDelegationGroup(delegation);
    const url = this.starfishConfig.starfishServices + 'delegationGroups';
    return this.http.post<Response>(url, serverDelegation, { headers }).pipe(catchError(handleErrorFromRest));
  }

  addNewDelegationAssignment(cd: ConfiguredDelegation): Observable<ClientAssignmentResponse[]> {
    const serverAssignmentList: ServerDelegationAssignment[] = cd.assignedRoles
      ?.filter((ar) => !!ar?.hasBeenModified)
      .map(this.toServerDelegationAssignment.bind(this));
    const postUrl = this.starfishConfig.starfishServices + 'delegationGroups/' + cd.delegation.id + '/assignments';

    const serverCalls$: Observable<ClientAssignmentResponse>[] = [];

    serverAssignmentList?.forEach((element) => {
      if (!element.assignmentId) {
        // this is a brand new assignment that we are adding
        serverCalls$.push(
          this.http
            .post<ClientAssignmentResponse>(postUrl, element, {
              headers,
            })
            .pipe(
              map((res) => res),
              catchError((e: HttpErrorResponse) => {
                const errorMessage: string = e?.error?.errorMessage[0];

                if (e?.status === 400 && errorMessage?.indexOf('Assignment already exists') >= 0) {
                  // if we get a duplicate message from this endpoint, just skip and move on.
                  const car: ClientAssignmentResponse = {
                    assignmentId: element.assignmentId ? element.assignmentId : -1,
                    delegatedRoleId: element.delegatedRoleId ? element.delegatedRoleId : -1,
                    roleId: element.roleId,
                    roleName: element.roleName,
                    userid: element.userid,
                  };
                  return of(car);
                }
                // otherwise this is probably a real issue - 500 or something
                return throwError(e);
              })
            )
        );
      } else {
        // this is a previously existing assignment that we are updating
        const putUrl =
          this.starfishConfig.starfishServices +
          'delegationGroups/' +
          cd.delegation.id +
          '/assignments/' +
          element.assignmentId;
        serverCalls$.push(this.http.put<ClientAssignmentResponse>(putUrl, element, { headers }));
      }
    });

    return this.assignCalendarManagement(cd).pipe(
      mergeMap((res) =>
        // we have an array of calls to either create
        // new assignments or update existing assignments.
        // we call all of those and only respond once all the calls are complete.
        // we return the id responses of the post/put in order to get the assignment id..
        concat(serverCalls$).pipe(
          concatMap((singleResponse) => singleResponse),
          toArray(),

          catchError((error) => throwError(error))
        )
      )
    );
  }

  getCurrentSemester(): Observable<ClientCurrentSemester> {
    const semesterUrl = this.starfishConfig.starfishServices + 'semesters/semesters';

    return this.http.get<ServerCurrentSemester[]>(semesterUrl, { headers }).pipe(
      map((serverSemesterModelArray) => this.toClientSemester(serverSemesterModelArray)),
      catchError(() => {
        // we don't want to end processing if there is an error with this endpoint- just return a default value.
        const emptySemester: ClientCurrentSemester = {
          name: '',
          isInTransitionPeriod: false,
        };
        return of(emptySemester);
      })
    );
  }

  // This will prune out calendar managers that don't match the type of the delgation group
  filterCalMgrs(type: CalendarManagementType, calMgrs: UserInfo[], expected: CalendarManagementType): string[] {
    if (!type) {
      return [];
    }
    if (type !== 'BOTH' && type.toString() !== expected) {
      return [];
    }
    return calMgrs ? calMgrs.map(this.toUserIdList.bind(this)) : [];
  }

  // If we pull the data. and no 'CURRENT' value is available,
  //    then I know I'm in the term transition, and I use the value of the 'NEXT" as the semester value to update the assignment with
  //    else we are not in a term transition, return the current and boolean indicating not in a transition
  private toClientSemester(scsArray: ServerCurrentSemester[]): ClientCurrentSemester {
    const isCurrent = (scs: ServerCurrentSemester) => scs.position === 'CURRENT';
    const isNext = (scs: ServerCurrentSemester) => scs.position === 'NEXT';

    const scsCurrent: ServerCurrentSemester[] = filter(isCurrent, scsArray);
    const scsNext: ServerCurrentSemester[] = filter(isNext, scsArray);

    if (scsNext.length === 0) {
      // somethign went wrong - we shouldn't get this value
      return {
        name: '',
        isInTransitionPeriod: false,
      };
    } else if (scsCurrent.length === 0 || Object.keys(this.featureConfig).includes('testTermTransitionFeatureFlag')) {
      // if no 'CURRENT' is available, we are in a term transition. return the 'next' term and true for isInTransition
      return {
        name: scsNext[0].name,
        isInTransitionPeriod: true,
      };
    }
    // else current must be available, we are NOT in a term transition. return the 'current' term and false for isInTransition
    return {
      name: scsCurrent[0].name,
      isInTransitionPeriod: false,
    };
  }

  private getDelegationAssignmentsTransitionStatus(
    cd: ConfiguredDelegation
  ): Observable<ClientAssignmentTransitionStatus[]> {
    const groupsUrl =
      this.starfishConfig.starfishServices + 'delegationGroups/' + cd.delegation.id + '/migrationStatus';

    return this.http.get<ServerMigrationStatus[]>(groupsUrl, { headers }).pipe(
      map((result) => result.map(this.toClientAssignmentTransitionStatus)),
      catchError(() =>
        // we don't want to end processing if there is an error with this endpoint- just return an empty set.
        of([])
      )
    );
  }

  private assignCalendarManagement(cd: ConfiguredDelegation): Observable<Response> {
    const url = this.starfishConfig.starfishServices + 'delegationGroups/' + cd.delegation.id + '/calendarManagement';

    const data: ServerCalendarManager = {
      calendarManagementExclusions: cd.delegation?.calendarManagers?.calendarManagementExclusions
        ? cd.delegation.calendarManagers.calendarManagementExclusions.map(this.toUserIdList.bind(this))
        : [],
      regularCalendarManagers: cd.delegation?.calendarManagers?.regularCalendarManagers
        ? cd.delegation.calendarManagers.regularCalendarManagers.map(this.toUserIdList.bind(this))
        : [],
      studentCalendarManagers: cd.delegation?.calendarManagers?.studentCalendarManagers
        ? cd.delegation.calendarManagers.studentCalendarManagers.map(this.toUserIdList.bind(this))
        : [],
      calendarManagerKiosks: cd.delegation?.calendarKiosks
        ? cd.delegation.calendarKiosks.map(orgSelectItemToOrgModel)
        : [],
    };

    return this.http.post<Response>(url, data, { headers }).pipe(catchError(handleErrorFromRest));
  }

  private toClientAssignmentTransitionStatus(sms: ServerMigrationStatus): ClientAssignmentTransitionStatus {
    return {
      assignmentId: sms.assignmentId,
      status: sms.status === 'NOT_APPLICABLE' ? 'NOT_TRANSITIONABLE' : sms.status, // we don't need to support both NA and NT
    };
  }

  private toClientDelegationAssignment(
    server: ServerDelegationAssignment,
    cats: ClientAssignmentTransitionStatus[],
    rcList: RoleConfig[],
    calMgrExclusions: UserInfo[],
    displayName?: string
  ): ConfiguredAssignments {
    let roleConfig: RoleConfig = rcList[0];
    rcList.forEach((rc) => {
      if (rc.roleId === server.roleId.toString()) {
        roleConfig = rc;
      }
    });

    const isInCalMgrExlusionsList = this.isInExcludedList(server.userid, calMgrExclusions); // includes({ id: server.userid }, calMgrExclusions);

    const client: ConfiguredAssignments = {
      assignmentId: server.assignmentId,
      user: {
        displayName: displayName ?? server.userid,
        id: server.userid,
      },
      role: roleConfig,
      calendarManagementEnabled: !isInCalMgrExlusionsList,
      options: this.toClientConfiguredOptions(server, roleConfig),
      hasBeenModified: false,
      transitionState: this.canTransitionStatus(cats, server.assignmentId),
    };
    return client;
  }

  private canTransitionStatus(cats: ClientAssignmentTransitionStatus[], id: number | undefined): ClientTransitionType {
    if (!id) {
      return 'NOT_TRANSITIONABLE';
    }
    let status: ClientTransitionType = 'NOT_TRANSITIONABLE';
    cats.forEach((element) => {
      if (element.assignmentId === id) {
        status = element.status;
      }

      // This feature flag forces the 'transitionable' state, even outside of the transition window
      // It can be used for walking users through the assignment transition process without requiring
      // work from the back end.
      if (Object.keys(this.featureConfig).includes('testTermTransitionFeatureFlag')) {
        status = 'TRANSITIONABLE';
      }
    });
    return status;
  }

  private isInExcludedList(id: string, list: UserInfo[]): boolean {
    if (!list || list.length === 0) {
      return false;
    }
    return list.some((user) => user.id === id);
  }

  private toClientConfiguredOptions(server: ServerDelegationAssignment, rc: RoleConfig): ConfiguredOptions {
    const client: ConfiguredOptions = {
      connectedStudents: server.assignedOneToOnes ? server.assignedOneToOnes.map(this.toClientUserInfo.bind(this)) : [],
      courseConfig: this.toClientCourseConfigFromOptions(rc, server.assignedCourses),
      kiosksConfig: this.toClientSelectionConfig(server.assignedKiosks, rc.kiosksFromRole.selectionType),
      orgsConfig: {
        selectionType: rc.orgsFromRole.selectionType,
        selectedItems: server.assignedOrganizations
          ? server.assignedOrganizations.map(this.toClientOrgSelectionConfig.bind(this))
          : [],
      },
      servicesConfig: this.toClientSelectionConfig(server.assignedServices, rc.servicesFromRole.selectionType),
      studentSupporters: [],
    };
    return client;
  }

  private toClientOrgSelectionConfig(server: ServerAssignedOrgs): OrgModel {
    const client: OrgModel = {
      code: server.organizationId,
      name: server.organizationName,
    };
    return client;
  }

  private toServerDelegationAssignment(client: ConfiguredAssignments): ServerDelegationAssignment {
    const courseInfo = this.toDelegationCourseInfo(client.options.courseConfig);

    const server: ServerDelegationAssignment = {
      assignmentId: client.assignmentId,
      userid: client.user.id,
      roleName: client.role.displayName,
      roleId: +client.role.roleId,
      delegatedRoleId: client.role?.roleDelegationId,
      assignedOneToOnes: client.options?.connectedStudents?.map(this.toUserIdList.bind(this)),
      assignedKiosks: client.options?.kiosksConfig?.selectedItems
        ? client.options.kiosksConfig.selectedItems.map(this.toServerOrgItem.bind(this))
        : [],
      assignedServices: client.options?.servicesConfig?.selectedItems
        ? client.options.servicesConfig.selectedItems.map(this.toServerOrgItem.bind(this))
        : [],
      assignedOrganizations: client?.options?.orgsConfig?.selectedItems
        ? client.options.orgsConfig.selectedItems.map(this.toServerAssignedOrgs.bind(this))
        : [],
      assignedCourses: courseInfo.courses,
    };
    return server;
  }

  private toServerAssignedOrgs(client: OrgModel): ServerAssignedOrgs {
    return {
      organizationId: client.code,
      organizationName: client.name,
    };
  }

  private toServerOrgItem(client: OrgModel): ServerOrgItem {
    return {
      code: client.code,
      name: client.name,
    };
  }

  private toClientDelegationGroupMetaData(serverDel: ServerDelegationGroup): DelegationMetaData {
    return {
      name: serverDel.name,
      id: serverDel.id ? serverDel.id : -1,
    };
  }

  private toClientDelegationGroup(serverDel: ServerDelegationGroup): Delegation {
    const delRoles: RoleConfig[] = serverDel.delegatableRoles.map(this.toClientRoleConfig.bind(this));
    const sortedRoles: RoleConfig[] = delRoles.sort((a, b) =>
      a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase())
    );

    const clientDelegation: Delegation = {
      id: serverDel.id,
      name: serverDel.name,
      roleConfigs: sortedRoles,
      calendarManagement: this.toClientCalManagerType(serverDel.calendarManagerTypes),
      calendarManagers: {
        calendarManagementExclusions: serverDel.calendarManagementExclusions
          ? serverDel.calendarManagementExclusions.map(this.toClientUserInfo.bind(this))
          : [],
        regularCalendarManagers: serverDel.regularCalendarManagers
          ? serverDel.regularCalendarManagers.map(this.toClientUserInfo.bind(this))
          : [],
        studentCalendarManagers: serverDel.studentCalendarManagers
          ? serverDel.studentCalendarManagers.map(this.toClientUserInfo.bind(this))
          : [],
      },
      calendarKiosks: serverDel.calendarManagerKiosks
        ? serverDel.calendarManagerKiosks.map(orgModelToOrgSelectItem)
        : [],
      admins: serverDel.delegatingAdmins.map(this.toClientUserInfo.bind(this)),
    };
    return clientDelegation;
  }

  private toClientRoleConfig(sdr: ServerDelegateRole): RoleConfig {
    const clientRoleConfig: RoleConfig = {
      roleSelectionType: this.toClientRoleSelectionType(sdr.type),
      roleDelegationId: sdr.id,
      roleId: sdr.role.id.toString(),
      displayName: sdr.role.name ? sdr.role.name : 'Not Supplied',
      servicesFromRole: {
        selectedItems: sdr.role.serviceConfiguration?.services ? sdr.role.serviceConfiguration?.services : [],
        selectionType: this.toSelectionType(sdr.role?.serviceConfiguration?.deferServiceSelection),
      },
      kiosksFromRole: {
        selectedItems: sdr.role.kioskConfiguration?.kiosks ? sdr.role.kioskConfiguration?.kiosks : [],
        selectionType: this.toSelectionType(sdr.role?.kioskConfiguration?.deferKioskSelection),
      },
      orgsFromRole: {
        selectedItems: sdr.role.organizationConfiguration?.organizations
          ? sdr.role.organizationConfiguration?.organizations
          : [],
        selectionType: this.toSelectionType(sdr.role?.organizationConfiguration?.deferOrganization),
      },
      servicesFromDelegation: {
        selectionType: this.toSelectionType(sdr.delegationService?.deferServiceSelection),
        selectedItems: sdr.delegationService ? sdr.delegationService.services : [],
      },
      kiosksFromDelegation: {
        selectionType: this.toSelectionType(sdr.delegationKiosk?.deferKioskSelection),
        selectedItems: sdr.delegationKiosk ? sdr.delegationKiosk.kiosks : [],
      },
      orgsFromDelegation: {
        selectionType: this.toSelectionType(sdr.delegationOrganizations?.deferOrganization),
        selectedItems: sdr.delegationOrganizations ? sdr.delegationOrganizations.organizations : [],
      },
      connectedStudents: [],
      studentSupporters: [],
      courseConfig: this.toClientCourseConfig(sdr.delegationCourses?.courseType, sdr.delegationCourses),
    };

    return clientRoleConfig;
  }

  private toClientCourseConfigFromOptions(rc: RoleConfig, server: ServerCoursesOptions[]): CourseConfig | undefined {
    if (!server || server?.length === 0) {
      return undefined;
    }

    const classList: ClassConfig[] = [];

    server.forEach((element) => {
      classList.push({
        course: element.courseNumber,
        section: element.sectionNumber ? element.sectionNumber : 'ALL',
        subject: element.department,
      });
    });

    classList.sort((a, b) => (a.subject < b.subject ? -1 : a.subject > b.subject ? 1 : 0));

    const cc: CourseConfig = {
      type: this.toCourseDeferType(rc.courseConfig?.type),
      campus: server[0].campus,
      assignCampus: rc.courseConfig?.assignCampus ? rc.courseConfig?.assignCampus : false,
      educationalTrack: server[0].track,
      assignEducationalTrack: rc.courseConfig?.assignEducationalTrack ? rc.courseConfig?.assignEducationalTrack : false,
      semester: server[0].termName,
      assignSemester: false,
      classConfigArray: classList,
      assignClasses: rc.courseConfig?.assignClasses ? rc.courseConfig?.assignClasses : false,
    };

    return cc;
  }

  private toClientCourseConfig(type: string, server: ServerCourseConfiguration): CourseConfig | undefined {
    if (!server || server.courses?.length === 0) {
      return undefined;
    }

    const sco: ServerCoursesOptions[] = server.courses;
    const classList: ClassConfig[] = [];

    sco.forEach((element) => {
      classList.push({
        course: element.courseNumber,
        section: element.sectionNumber ? element.sectionNumber : 'ALL',
        subject: element.department,
      });
    });

    const cc: CourseConfig = {
      type: this.toCourseDeferType(type),
      campus: server.courses[0].campus,
      assignCampus: !server.deferOptions.deferCampusSelection,
      educationalTrack: server.courses[0].track,
      assignEducationalTrack: !server.deferOptions.deferTrackSelection,
      semester: server.courses[0].termName,
      assignSemester: false,
      classConfigArray: classList,
      assignClasses: !server.deferOptions.deferCourseSelection,
    };

    return cc;
  }

  private toClientCalManagerType(server: string[]): CalendarManagementType {
    if (!server) {
      return 'NONE';
    }
    if (server.length === 2) {
      return 'BOTH';
    }
    if (server[0] === 'REGULAR') {
      return 'REGULAR';
    }
    if (server[0] === 'STUDENT') {
      return 'STUDENT';
    }
    return 'NONE';
  }
  private toServerCalManagerType(client: CalendarManagementType): string[] {
    if (client === 'BOTH') {
      return ['REGULAR', 'STUDENT'];
    }
    if (client === 'NONE') {
      return [];
    }
    return [client];
  }

  private toUserIdList(client: UserInfo): string {
    return client.id;
  }

  private toServerDelegateRole(rc: RoleConfig): ServerDelegateRole {
    const sdr: ServerDelegateRole = {
      id: rc.roleDelegationId,
      type: this.toServerDelegateType(rc.roleSelectionType),
      role: {
        type: rc.roleSelectionType,
        id: +rc.roleId,
      },
      delegationKiosk: this.toDelegationKiosk(rc.kiosksFromDelegation),
      delegationService: this.toDelegationService(rc.servicesFromDelegation),
      delegationCourses: this.toDelegationCourseInfo(rc.courseConfig),
      delegationOrganizations: this.toDelegationOrganizations(rc.orgsFromDelegation),
    };

    return sdr;
  }

  /**
   * @description must return:ALL_STUDENTS, ORGANIZATION, ONE_TO_ONE, COURSE
   * @private
   * @param rc
   * @returns string - coforming to endpoint
   */
  private toServerDelegateType(rst: RoleSelectionType): string {
    if (rst === 'ORG') {
      return 'ORGANIZATION';
    }
    if (rst === 'COURSES') {
      return 'COURSE';
    }
    return rst;
  }

  private toClientRoleSelectionType(rst: string | undefined): RoleSelectionType {
    if (!rst) {
      return 'ALL_STUDENTS';
    }
    if (rst === 'ORGANIZATION') {
      return 'ORG';
    }
    if (rst === 'COURSE') {
      return 'COURSES';
    }
    if (rst === 'ONE_TO_ONE') {
      return 'ONE_TO_ONE';
    }
    return 'ALL_STUDENTS';
  }

  private toDelegationCourseInfo(client: CourseConfig | undefined): ServerCourseConfiguration {
    if (!client) {
      return {
        courseType: 'DEFER_COURSES',
        courses: [
          {
            campus: 'ALL',
            track: 'ALL',
            termName: 'ALL',
            courseNumber: 'ALL',
            sectionNumber: 'ALL',
            department: 'ALL',
          },
        ],
        deferOptions: {
          deferCampusSelection: true,
          deferTrackSelection: true,
          deferSemesterSelection: true,
          deferCourseSelection: true,
        },
      };
    }

    const scoList: ServerCoursesOptions[] = [];
    const classList: ClassConfig[] = client.classConfigArray;
    if (classList && classList[0] && classList.length === 1) {
      classList.forEach((element) => {
        const sco: ServerCoursesOptions = {
          campus: client.campus,
          track: client.educationalTrack,
          termName: client.semester,
          courseNumber: element.course,
          sectionNumber: element.section,
          department: element.subject,
        };
        scoList.push(sco);
      });
    } else if (classList && classList[0] && classList.length > 1) {
      classList.forEach((element) => {
        const sco: ServerCoursesOptions = {
          campus: client.campus,
          track: client.educationalTrack,
          termName: client.semester,
          courseNumber: element.course,
          sectionNumber: element.section,
          department: element.subject,
        };
        if (element.subject !== 'ALL') {
          scoList.push(sco);
        }
      });
    } else {
      // no specific classes were set - so set to ALL
      const sco: ServerCoursesOptions = {
        campus: client.campus ? client.campus : 'ALL',
        track: client.educationalTrack ? client.educationalTrack : 'ALL',
        termName: client.semester ? client.semester : 'ALL',
        courseNumber: 'ALL',
        sectionNumber: 'ALL',
        department: 'ALL',
      };
      scoList.push(sco);
    }

    const scc: ServerCourseConfiguration = {
      courseType: client.type ? client.type : 'DEFER_COURSES',
      courses: scoList,
      deferOptions: {
        deferCampusSelection: !client.assignCampus,
        deferTrackSelection: !client.assignEducationalTrack,
        deferSemesterSelection: !client.assignSemester,
        deferCourseSelection: !client.assignClasses,
      },
    };

    return scc;
  }

  private toDelegationOrganizations(client: SelectionConfig<OrgModel>): ServerOrgConfiguration {
    const soc: ServerOrgConfiguration = {
      allowMultipleOrganizations: false,
      organizations: client?.selectedItems ? this.filterAll(client.selectedItems) : [],
      deferOrganization: client.selectionType === 'ADMIN_AREA_CHOOSE',
    };
    return soc;
  }
  private toDelegationKiosk(client: SelectionConfig<OrgModel>): ServerKioskConfiguration {
    const skc: ServerKioskConfiguration = {
      deferKioskSelection: client?.selectionType === 'ADMIN_AREA_CHOOSE',
      kiosks: client?.selectedItems ? this.filterAll(client.selectedItems) : [],
      displayKiosk: client?.selectedItems?.length > 0,
    };
    return skc;
  }

  private filterAll(modelList: OrgModel[]) {
    if (modelList.length === 1 && (modelList[0] as any) === 'ALL') {
      return [];
    }
    return modelList;
  }

  private toClientSelectionConfig(
    serverItems: ServerOrgItem[],
    selectionType: SelectionType
  ): SelectionConfig<OrgModel> {
    const client: SelectionConfig<OrgModel> = {
      selectionType,
      selectedItems: serverItems,
    };
    return client;
  }

  private toDelegationService(client: SelectionConfig<OrgModel>): ServerServiceConfiguration {
    const ssc: ServerServiceConfiguration = {
      deferServiceSelection: client?.selectionType === 'ADMIN_AREA_CHOOSE',
      services: client?.selectedItems ? this.filterAll(client.selectedItems) : [],
      displayService: client?.selectedItems?.length > 0,
    };
    return ssc;
  }

  private toCourseDeferType(s: string | undefined): ClientCourseSelectionType {
    return 'DEFER_COURSES';
  }

  private toSelectionType(b: boolean | null | undefined): SelectionType {
    if (b) {
      return 'ADMIN_AREA_CHOOSE';
    }
    return 'SPECIFIC_SERVICES';
  }

  private toClientUserInfo(adminId: string): UserInfo {
    return {
      id: adminId,
      displayName: adminId,
    };
  }

  private toServerDelegationGroup(del: Delegation): ServerDelegationGroup {
    let calMgrKiosks: ServerOrgItem[] = [];
    if (del.calendarManagement === 'BOTH' || del.calendarManagement === 'STUDENT') {
      calMgrKiosks = del.calendarKiosks ? del.calendarKiosks.map(orgSelectItemToOrgModel) : [];
    }

    const sdg: ServerDelegationGroup = {
      id: del.id,
      name: del.name,
      delegatableRoles: del.roleConfigs.map(this.toServerDelegateRole.bind(this)),
      calendarManagementExclusions: del.calendarManagers?.calendarManagementExclusions
        ? del.calendarManagers.calendarManagementExclusions.map(this.toUserIdList.bind(this))
        : [],
      calendarManagerTypes: this.toServerCalManagerType(del.calendarManagement),
      delegatingAdmins: del.admins ? del.admins.map(this.toUserIdList.bind(this)) : [],
      regularCalendarManagers: this.filterCalMgrs(
        del.calendarManagement,
        del.calendarManagers?.regularCalendarManagers,
        'REGULAR'
      ),
      studentCalendarManagers: this.filterCalMgrs(
        del.calendarManagement,
        del.calendarManagers?.studentCalendarManagers,
        'STUDENT'
      ),
      calendarManagerKiosks: calMgrKiosks,
    };
    return sdg;
  }
}
